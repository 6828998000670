import * as React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  MVPContainer,
  MVPContentContainer,
  MVPImageContainer,
  PerkContainer,
  PerkContainerTitle,
  CardContainer,
  StyledCard,
  Subtitle,
  Description,
  Icon,
  PerkIcon,
  StyledButton,
  MobileHeading,
  StyledAnchor,
  CaseStudyImage,
  StyledHeading,
  StyledHeadingLanding,
} from "./BuildMVP.styles";
import { Heading, Paragraph } from "../Typography/Typography";
import useMediaQuery from "../../hooks/useMediaQuery";
import { size } from "../../theme/sizes";

import MVPImage from "../../images/app-development-rafiki.png";
import BackgroundImage from "../../images/content_icons/background.png";
import OnboardingImage from "../../images/content_icons/customer_onboarding.png";
import CostEfectiveSupportImage from "../../images/content_icons/cost_effective.png";
import PlanningImage from "../../images/content_icons/ui_ux_valuation.png";
import TechExpertiseImage from "../../images/content_icons/product.png";
import IterativeDevImage from "../../images/content_icons/iterative_development.png";
import CostImage from "../../images/content_icons/technology_evaluation.png";
import TestingImage from "../../images/content_icons/technical_infra.png";
import LeaseSuranceImage from "../../images/leasesurance.png";

const BuildMVP = () => {
  const isMobile = useMediaQuery(size.tabletL);

  return (
    <MVPContainer id="#build-mvp">
      <MVPContentContainer>
        {isMobile && (
          <MobileHeading weight="semiBold">
            The Challenge: Bringing Ideas to Life
          </MobileHeading>
        )}
        <MVPImageContainer src={MVPImage} alt="build-an-mvp" />
        <PerkContainer>
          {!isMobile && (
            <StyledHeadingLanding weight="semiBold">
              The Challenge: Bringing Ideas to Life
            </StyledHeadingLanding>
          )}
          <Paragraph>
            Non-technical founders who have great ideas can face delays in materializing
            them due to a lack of technical expertise in initiating the build process.
          </Paragraph>
        </PerkContainer>
      </MVPContentContainer>
      <PerkContainer>
        <PerkContainerTitle weight="semiBold">Our Services</PerkContainerTitle>
        <CardContainer>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={PlanningImage} alt="icon" />
              Scope
            </Subtitle>
            <Description>
              Take your ideas and scope them into what defines an MVP
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TechExpertiseImage} alt="icon" />
              Define
            </Subtitle>
            <Description>
              Translate scope of features into product requirements and define
              your technology stack
            </Description>
          </StyledCard>

          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={IterativeDevImage} alt="icon" />
              Build
            </Subtitle>
            <Description>
              Build a team matching the tech stack and begin development
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={CostImage} alt="icon" />
              Test
            </Subtitle>
            <Description>
              Iterate development with unit and performance testing
            </Description>
          </StyledCard>
          <StyledCard>
            <Subtitle weight="semiBold">
              <PerkIcon src={TestingImage} alt="icon" />
              Deploy
            </Subtitle>
            <Description>
              Deliver an MVP with the necessary automations, enabling you
              to integrate and deploy continuously.
            </Description>
          </StyledCard>
        </CardContainer>
        <StyledAnchor
          target="_blank"
          href="https://airtable.com/shrX9O91vMPtuEy99"
        >
          <StyledButton
            label="I want to build an MVP"
            variant="primary"
            weight="semiBold"
          />
        </StyledAnchor>
      </PerkContainer>

      <StyledHeading weight="semiBold">Case Study: LeaseSurance</StyledHeading>
      <CaseStudyImage src={LeaseSuranceImage} alt="LeaseSurance" />
      <VerticalTimeline lineColor="#3252A0">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            border: "2px solid #3252A0",
            borderRadius: 8,
          }}
          contentArrowStyle={{ borderRight: "7px solid #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={BackgroundImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">Background</Subtitle>
          <Description>
            LeaseSurance is an insurance technology company sitting at the cross
            section of real estate and insurance. LeaseSurance replaces cash
            deposits with lease insurance technology, providing properties with
            more protection on every lease, and at the same time, making renting
            more affordable for tenants.
          </Description>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={OnboardingImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">
            Challenge 1: Customer Onboarding
          </Subtitle>
          <Description>
            LeaseSurance’s method of operating posed a challenge for
            LeaseSurance to scale. The company heavily relied on Excel
            spreadsheets for all of their data collection and customer
            onboarding processes. This manual process frustrated the company’s
            internal and external processes. When a new client was signed,
            LeaseSurance associates took anywhere from 8 to 12 hours to clean
            their data before they could assess, analyze and manage the data to
            sell their insurance service. With LeaseSurance’s growing client
            base, the company needed an automated and strategic method of
            operation to increase inefficiency.
          </Description>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ border: "2px solid #3252A0", borderRadius: 8 }}
          contentArrowStyle={{ borderRight: "7px solid  #3252A0" }}
          iconStyle={{
            border: "2px solid #3252A0",
            color: "#fff",
            padding: isMobile ? 6 : 12,
            background: "#FFFFFF",
          }}
          icon={<Icon src={CostEfectiveSupportImage} alt="icon" />}
        >
          <Subtitle weight="semiBold">alphathesis’s Impact</Subtitle>
          <Description>
            alphathesis architected and built a highly scalable platform,
            leveraging robust technologies that not only streamlined their
            clients’ operations but also positioned them for exponential growth.
            For example, LeaseSurance was able to onboard a client with 7
            properties and 2386 units onto the new platform in 6 minutes, which
            would have taken them multiple days had they used Excel.
          </Description>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </MVPContainer>
  );
};

export default BuildMVP;
